import "./src/components/layout.css"
import "@fontsource/poppins/latin-400.css";
import "@fontsource/poppins/latin-500.css"
import "@fontsource/poppins/latin-600.css"
import "@fontsource/poppins/latin-700.css"

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log("route updated", location?.pathname);
    return null;
  }

  const pagePath = location ? location.pathname + location.search + location.hash : undefined;
  setTimeout(() => {
    console.log("registering page view", pagePath);
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'page_view', { page_path: pagePath });
    }
  }, 100);
};